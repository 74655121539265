@import '../../styles/variables';
@import '../../styles/mixin';

.product__sidebyside {
    &_body {
       @include media(sm){
         position: relative;
       }
    }

    &_title {
        font-weight: 700;
        font-size: 2rem !important;
        line-height: 40px !important;
        letter-spacing: -0.01em;

        @media screen and (max-width: 520px) {
          br {
            display: none;
          }
        }

        @include media(xl) {
          font-size: 3rem !important;
          line-height: 60px !important;
        }
    }

    &_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        row-gap: 12px;

        @include media(sm){
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 550px;
            column-gap: 24px;
            overflow-y: hidden;
        } 

        &_invested {
            @include media(sm){
                .product__sidebyside_content_product {
                    order: 1;
                }
                
                .product__sidebyside_info_content {
                    order:2;
                }

            }
           
        }
    }

    &_content_product {
        align-items: center;
        display: grid;
        grid-row-gap: 16px;
        justify-items: center;
        overflow-y: auto;
        overscroll-behavior: contain;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;
        width: 100%;

        &::-webkit-scrollbar {
           display: none;
        }

        @include media(sm) {
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
            grid-row-gap: 24px;
        }

        @include media(md) {
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        }

        .content__product {
            background-color: inherit;
             
            @include media(sm){
                max-width: 194px;
            }

            @include media(md){
                max-width: 238px;
            }            
        }

        &_large {        
            @include media(sm) {
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
                grid-row-gap: 24px;
            }

            @include media(md) {
                grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
            }

            @include media(2xl) {
                grid-row-gap: 36px;
                grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
            }

            @include media(3xl) {
                grid-template-columns: repeat(auto-fill, minmax(488px, 1fr));
            }   
            
            .content__product {
                @include media(md){
                    max-width: 236px;
                }

                @include media(lg){
                    max-width: 320px;
                }

                @include media(2xl){
                    max-width: 360px;
                }

                @include media(3xl){
                    max-width: 490px;
                }
            }
        }

    }

    &_info_content {       
        display: flex;
        min-height: 200px;
        border-radius: $border-radius-card; 

        @media screen and (max-width: 639px) {
            position: relative;
            border-radius: 0;
        }

        &_not_fullwidth {
          position: relative;    
        }

        .image_section, .overlay {
            position: absolute;
            top: 0;
            left: -15px;
            width: 100vw;
            height: 100%;
            object-fit: cover; 

            @include media(sm) {
               width: 100%;
               left: 0;
            }

            &__full_width_left {   
                @include media(sm){
                   left: calc(-100vw + 50% - 12px);
                   width: 100vw;
                } 
            } 
            
            &__full_width_rigth {  

                @include media(sm){
                   left: calc( 50% + 12px);
                   right: 0;
                   width: 50vw;
                } 
            } 
            
            &_border_radius {

                @include media(sm){
                    border-radius: $border-radius-card; 

                    img {
                        border-radius: $border-radius-card;  
                    }
                }               
            }
        }

        .overlay {
            z-index: 1;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.42) 100%);

            &__border_radius {
                @include media(sm){
                   border-radius: $border-radius-card;  
                }
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            padding: 20px 10px;
            width: 100%;
            row-gap: 24px;
            position: relative;
            padding: 24px 0;
            z-index: 2;

            @include media(sm){
              padding: 36px;
            }

            @include media(lg){
              padding: 48px;
            }

            &_full_width {
                @include media(sm){
                   padding: 48px 48px 48px 0;
                }  
            }
        }

        .products__see_all {
           display: flex;
           width: fit-content;
           align-items: center;
           font-weight: 600;
           column-gap: 8px;

           i {
              font-size: 600;
              font-size: $text-xl;
           }
        }
    }

    .products__carousel {
      > div {
        padding: 0 !important;
      }
    }

    .product__shop_all_slider {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 12px;
        color: $text-see-all-btn !important;
        max-width: 96px !important;
        background: $see-all-btn;
        height: 100%;
        width: 100%;
        border-radius: $border-radius-card;
        padding: 0 18px;
        font-weight: 500;

        p {
            text-transform: uppercase;
            color: $text-see-all-btn !important;
            font-weight: 700;
            min-width: 80px;
        }

        i {
            width: 16px;
            height: 16px;
        }
    }
}