@import '../../styles/variables';
@import '../../styles/mixin';

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.product {
  $body-gap: 10px;
  border-radius: $border-radius-card;
  background: $background-product-card;
  border: $product-card-border;
  box-shadow: $box-shadow-prodct;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  &:hover {
    z-index: $product-elevation-hover;
    background: get-value(rgba($background-product-card, 0.95), $background-product-card-hover);
    box-shadow: $box-shadow-prodct-hover;
    border-radius: $border-radius-card;
    border: $product-card-border-hover;
    transform: $product-transform-hover;
    animation: $product-animation-hover;
    transition: $product-transition;

    .product__info_carousel::after {
      background: linear-gradient(
        270deg,
        $background-product-card-hover 60%,
        transparent
      );
    }
  }

  .product__link_image {
    position: relative;
  }

  .product__image__load {
    background: $product-placeholder-image-color;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .product__image,
  .product__image_large {
    flex-shrink: 0;
    order: 0;
    position: relative;
    width: 100%;
    z-index: 1;
    padding-top: 76.27%;
    overflow: hidden;
    height: 0;
    border-radius: $border-radius-card $border-radius-card 0px 0px;

    img {
      height: auto;
    }
  }

  .product__in_stock {
    border-radius: 20px;
    color: get-value($gray-300, $product-stock-text);
    background-color: get-value(none, $product-stock-background);
    font-size: $text-xs;
    padding: 4px;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translate(-50%, 0);

    @media screen and (max-width: 400px) {
      font-size: 0.5rem;
      bottom: 2px;
    }
  }

  .product__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 1;
    padding: 12px;

    @include media(sm) {
      padding: 20px;
    }
  }

  .product__name_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .product__name {
    font-size: $text-lg !important;
    color: $product-title !important;
    font-weight: 600;
    line-height: 22px;
    max-width: fit-content;
    letter-spacing: -2%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    @supports (-webkit-line-clamp: 2) {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
    }

    @include media(sm) {
      font-size: $text-xl !important;
      line-height: 24px;
    }
  }

  .brand,
  .category {
    color: $product-brand;
    font-size: $text-sm;
    font-weight: 400;
    line-height: 16px;
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .brand {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    padding-bottom: 3px;

    @include media(sm) {
      font-size: $text-xs !important;
    }
  }

  .info__brand_category {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    justify-content: space-between;
    padding: 12px 0;
    width: 100%;
  }

  .category {
    flex-grow: 1;
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    font-weight: 500 !important;
    color: $product-brand !important;
  }

  .flower__type {
    height: 20px;
    flex-shrink: 0;
    width: fit-content;
    min-width: 24px;
    display: flex;
    line-height: 14px;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border-radius: 7px;
    font-size: $text-xs;
    border-radius: $product-category-border-radius;
    cursor: default;

    strong {
      text-transform: uppercase;
    }
  }

  $flower_types: (
    'default' $flower-type-default-bg-color $flower-type-default-color,
    'sativa' $flower-type-sativa-bg-color $flower-type-sativa-color,
    'indica' $flower-type-indica-bg-color $flower-type-indica-color,
    'hybrid' $flower-type-hybrid-bg-color $flower-type-hybrid-color,
    'vape' $flower-type-vape-bg-color $flower-type-vape-color,
    'extract' $flower-type-extract-bg-color $flower-type-extract-color,
    'si' $flower-type-si-bg-color $flower-type-si-color,
    'is' $flower-type-is-bg-color $flower-type-is-color
  );

  .price {
    display: flex;
    align-items: flex-end;
    width: fit-content;
    column-gap: 8px;
    font-size: $text-lg;
    line-height: 18px;
    letter-spacing: 0.01px;

    &__overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ins {
      order: 1;
      line-height: 1;
      text-decoration: none;
      color: $product-price;
      font-weight: 500;

      @include media(sm) {
        font-size: $text-xl;
        line-height: 20px;
      }
    }

    del {
      order: 2;
      display: block;
      font-size: $text-xs;
      line-height: 1;
      color: $discount-price;

      @include media(sm) {
        font-size: $text-sm;
        line-height: 1rem;
      }
    }

    &__expanded_type,
    &__expanded_tax_type {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: 420px) {
        flex-direction: row;
        justify-content: space-between;
      }

      ins {
        line-height: 24px;
      }

      del {
        text-align: end;
        line-height: 1rem;
      }

      > div:nth-child(2) {
        margin-top: 4px;

        @media screen and (max-width: 420px) {
          display: none;
        }
      }
    }

    &__expanded_tax_type {
      justify-content: flex-start;

      @media screen and (min-width: 420px) {
        align-items: flex-end;
        justify-content: space-between;
      }

      .price__tax_message {
        line-height: 20px;
      }
    }

    &__column-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 4px;
      width: 100%;

      &_overflow {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ins {
        order: 1;
        line-height: 24px;
        max-width: 80px;
        flex-shrink: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      del {
        text-align: end;
        line-height: 1rem;
      }
    }

    &__tax_message {
      order: 6;
      white-space: nowrap;
      font-size: $text-sm;
      line-height: 1rem;
      color: $product-tax-applied-message;
    }

    &__discount {
      order: 3;
      line-height: 1rem;
      font-size: $text-xs;
      font-weight: 500;
      color: $product-price;
    }
  }

  .dot {
    order: 5;
    min-width: 4px;
    height: 4px;
    background-color: $product-tax-applied-message;
    border-radius: 50%;
  }

  .price__secundary_info {
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: 100%;

    @media screen and (max-width: 420px) {
      row-gap: 8px;
    }
  }

  .price__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    row-gap: 4px;

    &_overflow {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &_right {
      align-items: flex-end;
    }

    ins {
      order: 1;
    }

    del {
      text-align: start;
      line-height: 1rem;
    }
  }

  .price__ins_container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    column-gap: 4px;

    &_overflow {
      overflow: hidden;
    }
  }

  .variant_select_container {
    order: 2;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: $text-sm !important;
    line-height: 16px;
    max-width: 100%;
    width: fit-content;
    height: 17px !important;
    margin-bottom: 1.5px;
    color: $product-tax-applied-message;

    &_overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
    }

    .variant_select {
      max-width: 100%;
      width: fit-content;

      button {
        height: 16px !important;
        padding: 0;
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0,1fr) 16.5px;
        background: inherit;
        font-size: $text-sm !important;

        i {
          font-size: $text-base !important;
        }
      }

      .select__menu {
        min-width: fit-content;
      }
    }

    .variant_select__menu {
      padding-block: 8px;
      
      > div {
        max-height: 134px !important;
        
        * {
            font-size: $text-sm; 
            padding: 8px 16px; 
        }
      }         
    }

    .variation__message {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      min-width: 0;
    }
  }

  .info__price_variation {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 3px;
    margin-bottom: 12px;

    .price {
      @media screen and (max-width: 440px) {
        flex-direction: column-reverse;
        row-gap: 3px;
        align-items: start;
      }

      del {
        @media screen and (min-width: 440px) {
          align-self: flex-end;
          letter-spacing: 0.03px;
        }
      }
    }

    .variant_select_container {
       height: 16px !important;
       white-space: nowrap;
    }

    .tax_message {
      order: 4;
      width: fit-content;
      font-size: $text-xs;
      line-height: 14px;
      color: $product-tax-applied-message;
      letter-spacing: 0.01px;
    }
  }

  .info {
    line-height: 20px;
    font-weight: 700;
    font-size: $text-xs;
    display: flex;
    column-gap: 4px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    @include media(sm) {
      font-size: $text-sm;
    }

    svg {
      width: 16px;
      height: 16px;

      * {
        stroke: $product-info-text-color !important;
        stroke-width: 1.2;
      }
    }

    &__filled {
      padding: 4px 10px;
      background: $product-info-background;
      color: $product-info-text-color;
      border: 1px solid $product-info-broder;
      border-radius: $product-info-border-radius;
    }

    &__outlined {
      padding: 4px 10px;
      color: $product-info-text-color;
      border: 1px solid $product-info-broder;
      border-radius: $product-info-border-radius;
    }
  }

  @each $name, $bg, $color in $flower_types {
    .type__#{$name} {
      border: 1px solid $color !important;
      background-color: $bg !important;
      color: $color !important;
    }
  }

  .product__badges {
    display: flex;
    flex-direction: column;
    width: fit-content;
    row-gap: 6px;
    top: 16px;
    position: absolute;
    overflow: hidden;
    max-width: 60% !important;
    right: 16px;
    z-index: 2;

    @media screen and (max-width: 400px) {
      max-width: 50% !important;
    }
  }

  .badge {
    padding: 4px 10px;
    background: $badge;
    color: $badge-text;
    border: 1px solid $badge-border-color;
    border-radius: $border-radius-badge;
    height: 24px;
    margin-left: auto;
    line-height: 16px;
    font-weight: 700;
    font-size: $text-xs;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    a {
      color: $badge-bogo-text !important;
      line-height: 1rem;
      color: $badge-bogo-text !important;
      background-position: center bottom;
      background-repeat: repeat-x;
      background-size: 4px 1px;
      background-image: linear-gradient(
        to right,
        $badge-bogo-text 50%,
        transparent 50%
      );
    }

    a,
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    svg {
      flex-shrink: 0;
      margin-right: 4px;
      width: 12px;
      height: 12px;

      * {
        stroke: $badge-text !important;
        stroke-width: 2.5;
      }
    }

    &__discount {
      background: $badge-discount;
      color: $badge-discount-text;
      border: 1px solid $badge-discount-border-color;

      svg {
        * {
          stroke: $badge-discount-text !important;
        }
      }
    }

    &__seller {
      background: $badge-seller;
      color: $badge-seller-text;
      border: 1px solid $badge-seller-border-color;

      svg {
        * {
          stroke: $badge-seller-text !important;
        }
      }
    }

    &__specials {
      background: $badge-specials;
      color: $badge-specials-text;
      border: 1px solid $badge-specials-border-color;

      svg {
        * {
          stroke: $badge-specials-text !important;
        }
      }
    }

    &__warning {
      background: $badge-warning;
      color: $badge-text-warning;
      border: 1px solid $badge-warning-border-color;

      svg {
        * {
          stroke: $badge-text-warning !important;
        }
      }
    }

    &__bogo {
      background: $bagde-bogo;
      color: $badge-bogo-text;
      border: 1px solid $badge-bogo-border;

      svg {
        * {
          stroke: $badge-bogo-text !important;
          stroke-width: 2.5;
        }
      }
    }

    &__info {
      background: $product-info-background;
      color: $product-info-text-color;
      border: 1px solid $product-info-broder;

      svg {
        * {
          stroke: $product-info-text-color !important;
        }
      }
    }
  }

  .info__container {
    padding-bottom: 12px;

    .info__body {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .info__pack {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 3px;
      font-size: $text-xs;

      span {
        color: $product-tch-cbd !important;
      }

      svg {
        width: 14px;
        height: 14px;

        * {
          stroke: $product-tch-cbd;
        }
      }
    }
  }

  .product__actions_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;

    @media screen and (max-width: 420px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 12px;

      .actions__counter {
        order: 2;
      }
    }

    .actions__counter {
      width: fit-content;
      > button {
        padding: 0 4px !important;
        width: fit-content;
        font-size: $text-sm;
        height: auto;
        min-height: 16px;
        color: $label-btn-link;
        border: 0;
        background: transparent;

        > div {
          line-height: 22px;
          text-transform: inherit;
          text-decoration: underline;
          text-underline-offset: 2px;
        }

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          border: 0;
          color: $label-btn-link-hove;
          background: none;
        }
      }
    }
  }

  .actions__body {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    max-width: 54px;
    column-gap: 10px;
  }

  .icon__shopping {
    position: relative;

    .qty__badge {
      background: $badge-counter;
      border-radius: 50%;
      color: $badge-counter-text;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: $text-xs;
      height: 18px;
      width: 18px;
      line-height: 12px;
      right: -7px;
      top: -6px;
    }

    svg {
      height: 22px;
      width: 22px;
      * {
        stroke: $btn-actions-product;
      }
    }
  }

  .favorite_action {
    border-radius: 50% !important;
    padding: 0px !important;
    height: 32px !important;
    min-width: 32px !important;
    background: $btn-actions-product-background;

    i {
      color: $btn-actions-product;
      cursor: pointer;
      font-size: 20px;
    }

    &:hover, &:focus {
        background: $btn-actions-product-background-hover;

        i {
          color: $btn-actions-product;
        }
    }
  }

  .product__info_carousel {
    width: -webkit-fill-available;

    div[class~='swiper-slide'] {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    .brand,
    .category {
      padding: 0;
      text-overflow: unset !important;
      overflow: unset !important;
      width: 100%;
      box-sizing: border-box;
      min-width: fit-content;
    }
  }

  .product__info_carousel::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -10px;
    width: 16px;
    display: block;
    background: linear-gradient(
      270deg,
      $background-product-card 60%,
      transparent
    );
    z-index: 3;
  }
}

/// Placeholder

.product__placeholder,
.product__placeholder_large {
  border: 0 !important;
  width: 100%;

  @include media(sm) {
    min-width: 300;
  }

  @include media(md) {
    min-width: 236px;
  }

  .placeholder {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 4px;
    display: inline-block;
    background: $placeholder-bg !important;
  }

  figure {
    @extend .placeholder;
    background-color: $product-placeholder-image-color !important;
    position: relative;
    padding-top: 76.27%;
  }

  .product__body {
    min-height: 100px;
    padding: 8px;
    row-gap: 4px;
    flex-grow: 1;

    @include media(sm) {
      padding: 16px;
    }

    .product__brand {
      height: 13px;
      width: 38% !important;
    }

    .product__pack_info {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 4px;
      width: 100%;
      max-height: 8px;

      span {
        width: 65%;
      }
    }

    .product__current_price {
      margin: 6px 0 4px;
      width: 28%;
      height: 20px;
    }

    h5 {
      height: 20px !important;
      width: 90% !important;
    }

    .product__actions_placeholder {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      height: 25px;
      column-gap: 5px;

      .actions_placeholder {
        width: 55%;
        height: 100%;
      }

      div {
        display: flex;
        align-self: start;
        column-gap: 8px;
      }

      .type_placeholder,
      .btn_placeholder {
        width: 30px;
        height: 25px;
        border-radius: 35%;
        background-color: $placeholder-bg-100;
      }
    }

    h5,
    p,
    span {
      @extend .placeholder;
      height: 12px;
      width: 100%;
    }
  }
}

.product__placeholder_large {
  min-width: 214px;
  @include media(md) {
    min-width: 364px;
  }

  @include media(2xl) {
    min-width: 502px;
  }
}
