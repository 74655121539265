@import '../../styles/variables';
@import '../../styles/mixin';

// Styles for specials collections section from here.

.product_row__section {
  display: flex;
  align-items: center;
}

.products__see_all {
  font-weight: 500;
  color: $see-all-link;
  font-size: $text-sm;
  flex-shrink: 0;

  span {
    margin-left: 5px;
    color: $see-all-counter !important;
  }

  @include media(md) {
    font-size: $text-base;
  }
}

.see_all__socials {
  display: flex;
}

.product__shop_all_slider {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 12px;
  color: $text-see-all-btn !important;
  max-width: 96px !important;
  background: $see-all-btn;
  height: 100%;
  width: 100%;
  border-radius: $border-radius-card;
  padding: 0 18px;
  font-weight: 500;

  p {
    text-transform: uppercase;
    color: $text-see-all-btn !important;
    font-weight: 700;
    min-width: 80px;
  }

  i {
    width: 16px;
    height: 16px;
  }
}
